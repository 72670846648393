<template>
  <div>
    <colum-chart
      :series="series"
      :option="temp"
    />

    <div class="page-container-table">
      <!--Phần header của bảng -->
      <button-all-header
        :hideDelete="false"
        :hideImportFile="false"
        :arrayExcel="[]"
        :hideDowload="false"
        :hideAdd="false"
        class="custtom-header pr-1"
        @clickExportExcel="dowloadFile"
        @search="search($event)"
      />
      <!--Phần header của bảng -->
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: false,
        }"
        :sort-options="{
          enabled: false,
        }"
      />
      <!-- pagination -->
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="pageChange"
      />
    </div>
  </div>

</template>

<script>
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ColumChartOption from '../configChart/ColumChart'
import ColumChart from './component/ColomChart.vue'
import store from '../../store'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constansts/ConstanstApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    ColumChart,
  },
  data() {
    return {
      series: [],
      dataList: [],
      // ==dữ liệu bảng
      columns: [
        {
          label: 'TÊN THIẾT BỊ',
          field: 'name',
        },
        {
          label: 'SỐ LƯỢNG THIẾT BỊ',
          field: 'count',
        },
      ],
      // ==phân trang
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      urlQueryChart: {
        districtId: null,
        key: '',
      },
      totalRecord: 0,
      currentPage: 10,
      dataChart: [],
      ColumChartOption,
      temp: {},
    }
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {
    async dowloadFile() {
      const name = 'SoLuongThietBi.xlsx'
      await store.downloadExportFile(name, ConstantsApi.DOWLOAD_EXCEL)
    },
    // Lấy dữ liệu bảng
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_BHYT_BHXH, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      const listDevice = this.dataList.map(e => e.name)
      const value = this.dataList.map(e => e.count)
      const lists = []
      listDevice.forEach(e => {
        if (e.split(' ').length > 2) {
          lists.push(e.split(' '))
        } else {
          lists.push([e])
        }
      })
      lists.forEach((item, index, arr) => {
        if (item.length > 2) {
          let counter = 0
          let str = ''
          const result = []
          for (let i = 0; i < item.length; i++) {
            if (counter === 2) {
              result.push(`${str} ${item[i]}`)
              counter = 0
              str = ''
            } else {
              counter += 1
              str = `${str} ${item[i]}`
            }
          }
          if (str) {
            result.push(str)
          }
          arr.splice(index, 1, result)
        }
        return item
      })
      if (lists.length > 0) {
        this.ColumChartOption.xaxis.categories = lists
      } else {
        this.ColumChartOption.xaxis.categories = ['']
      }
      this.temp = {}
      this.$nextTick(() => {
        this.temp = this.ColumChartOption
      })
      this.series = [
        {
          name: 'Tên thiết bị',
          data: value,
        },
      ]
      this.$hideLoading()
    },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.urlQueryChart.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.urlQueryChart.key = ''
        this.fetchData(this.urlQuery)
      }
    },
  },
}
</script>

<style>

</style>
